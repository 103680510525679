  <template>
    <b-card title="Completed Delivery Picking Process">
      
      <b-row>
        <!-- Modal for showing Job Order Details When Clicking Approve-->
        <b-col cols="12">
          <b-row style="margin-bottom:15px;">
            <b-col cols="4" class="hide-on-mobile">
            </b-col>
            <b-col cols="4" class="hide-on-mobile">
            </b-col>
            <b-col cols="4" class="hide-on-mobile">
              <div style="display: flex; align-items: center;">
                <b-form-input
                  v-model="searchBox"
                  type="search"
                  placeholder="Search By SO number"
                  style="margin-right: 10px;"
                />
                <b-button @click="doFiltering" variant="primary">Search</b-button>
              </div>
            </b-col>

            <b-col cols="4" class="hide-on-desktop">
              <div>
                <b-form-input
                  v-model="searchBox"
                  type="search"
                  placeholder="SO Number"
                  style="margin-right: 10px;"
                />
              </div>
            </b-col>
            <b-col cols="4" class="hide-on-desktop">
              <b-button @click="doFiltering" variant="primary">Search</b-button>
            </b-col>
            <b-col cols="4" class="hide-on-desktop">
            </b-col>

            <b-modal
              v-model="promptDetail"
              :title="titleModal"
              id="myModal"
              size="xl"
              ok-only
            >
              <b-row>
                <b-col cols="12" style="margin-top:10px">
                  <b-row>
                    <b-col cols="4"><strong>SO Number</strong></b-col>
                    <b-col>: {{ form? form.delivery.orderNumber : ''}}</b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><strong>Order Date</strong></b-col>
                    <b-col>: {{ form? form.delivery.createdAt : ''}}</b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><strong>Delivery Address 1</strong></b-col>
                    <b-col>: {{ form? form.delivery.deliveryAddressOne : ''}}</b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4"><strong>Delivery Address 2</strong></b-col>
                    <b-col>: {{ form? form.delivery.deliveryAddressTwo : ''}}</b-col>
                  </b-row>
                  <br>
                  <br>
  
                  <b-table
                    :items="detail"
                    :fields="fieldPicklist"
                    responsive="xl"
                    hover
                    head-variant="dark"
                    outlined
                    small
                  >
                    <template #cell(no)="row">
                      {{ row.index + 1 }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <template #modal-footer>
                <div class="w-100"></div>
              </template>
            </b-modal>
          </b-row>
          <b-row style="margin-bottom:10px" class="show-on-mobile">
            <b-col>
              <b-form-checkbox
                v-model="stackedStatus"
                value="md"
                unchecked-value="false"
              >
                Stacked Table
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible;">
                <b-table
                  :filter="search"
                  hover
                  responsive
                  small
                  head-variant="dark"
                  outlined
                  :items="delivery"
                  :fields="headers"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                >
                  <template #cell(jobOrderID)="row">
                    {{ row.item.id }}
                  </template>
                  <template #cell(orderNumber)="row">
                    {{ row.item.orderNumber }}
                  </template>
                  <template #cell(item)="row">
                    {{ simplyfiedItems(row.item.item) }}
                  </template>
                  <template v-slot:cell(actions)="row">
                    <b-button
                      :variant="buttonColor(row.item.orderNumber).variantColor"
                      size="sm"
                      style="margin-right:10px"
                      @click="
                        detail(row.item);
                        promptDetail = true;
                        dataApproval = row.item;
                      "
                      >{{ buttonColor(row.item.orderNumber).variantText }}</b-button
                    >
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  @input="queryDelivery()"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @change="onPageChange"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    dateFormat,
    userAccess,
    userAccessManufacture,
    viewAccess,
  } from "@/utils/utils.js";
  
  import { mapActions, mapState } from "vuex";
  
  export default {
    data() {
      return {
        form: {
          orderNumber: [],
          data: [],
          delivery: {},
        },
        disablePick: false,
        deliveryStarted: false,
        stackedStatus: "md",
        showSpinner: false,
        headers: [
          {
            key: "orderNumber",
            label: "SO Number",
            filterable: true,
            sortable: true,
          },
          {
          key: "picklistNo",
          label: "Picklist Number",
          filterable: true,
          sortable: true,
        },
        {
          key: "companyName",
          label: "Company Name",
          filterable: true,
          sortable: true,
        },
        {
          key: "deliveryAddressOne",
          label: "Delivery address",
          filterable: true,
          sortable: true,
        },
        {
          key: "remark",
          label: "Remark",
          filterable: true,
          sortable: true,
        },
          { key: "actions", label: "Actions" },
        ],
        fieldPicklist: [
          {
            key: "picklistNumber",
            label: "Pick List Number",
            filterable: true,
            sortable: false,
          },
          {
            key: "lineItem",
            label: "Line Item",
            filterable: true,
            sortable: false,
          },
          {
            key: "lineDescription",
            label: "Line Description",
            filterable: true,
            sortable: false,
          },
          {
            key: "fromBatch",
            label: "Drum Number",
            filterable: true,
            sortable: false,
          },
          {
            key: "pickListType",
            label: "Pick List Type",
            filterable: true,
            sortable: false,
          },
          {
            key: "quantity",
            label: "Quantity",
            filterable: true,
            sortable: false,
          },
          {
            key: "unit",
            label: "Unit",
            filterable: true,
            sortable: false,
          },
          {
            key: "binLocation",
            label: "Bin Location",
            filterable: true,
            sortable: false,
          },
          {
            key: "props.statusNo",
            label: "Status",
            filterable: true,
            sortable: false,
          },
          {
            key: "cableLengthInfo",
            label: "Cable Length",
            filterable: true,
            sortable: false,
          },
          {
            key: "cableWeight",
            label: "Cable Weight",
            filterable: true,
            sortable: false,
          },
          {
            key: "machineName",
            label: "Machine",
            filterable: true,
            sortable: false,
          },
          {
            key: "user",
            label: "Cutter",
            filterable: true,
            sortable: false,
          },
        ],
        //sort direction list
        directions: [
          { key: false, label: "Asc", sortable: true },
          { key: true, label: "Desc", sortable: true },
        ],
        jobContext: null,
        search: null,
        pageOptions: [5, 10, 20, 100],
        sortBy: "",
        sortDesc: false,
        buttonState: false,
        currentPage: 1,
        perPage: 5,
        promptDetail: false,
        dataApproval: "",
        titleModal: "",
        totalRows: 1,
        searchBox: '',
        orderNumber: '',
        dataPicklist: [],
  
        //notify
          notifyModal:false,
          notifyData:{},
      };
    },
    mounted() {
      this.queryDelivery()
      // this.$store.dispatch("delivery/getDelivery");
  
      // Saving Menu Setting on localstorage session so it still same even after reloading the page
      if (this.$session.has("perPageCompletedDeliveryManufacture")) {
        this.perPage = this.$session.get("perPageCompletedDeliveryManufacture");
      }
      if (this.$session.has("sortByCompletedDeliveryManufacture")) {
        this.sortBy = this.$session.get("sortByCompletedDeliveryManufacture");
      }
      if (this.$session.has("sortDescCompletedDeliveryManufacture")) {
        this.sortDesc = this.$session.get("sortDescCompletedDeliveryManufacture");
      }
    },
  
    watch: {
      // Taking the Menu Setting from localstorage session so the setting will be the same as before
      perPage(perPageNew) {
        this.$session.set("perPageCompletedDeliveryManufacture", perPageNew);
      },
      sortBy(sortByNew) {
        this.$session.set("sortByCompletedDeliveryManufacture", sortByNew);
      },
      sortDesc(sortDescNew) {
        this.$session.set("sortDescCompletedDeliveryManufacture", sortDescNew);
      },
    },
  
    methods: {
      ...mapActions({
          getDeliveryOrders: "delivery/getDelivery",
        }),
        processMetadata(metadata) {
        console.log("metadata", metadata);
        let { totalData } = metadata;
        this.totalRows = totalData;
      },

      queryDelivery(){
        this.getDeliveryOrders({
          entry : this.perPage,
          page : this.currentPage,
          isCompleted : true,
          orderNumber : this.orderNumber,
        }).then((data) => {
          console.log('data', data);
          this.dataPicklist = data.dataPicklist
          this.processMetadata(data.metadata)
          this.isLoading = false
        }).catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },
    
      onPageChange(page) {
        this.currentPage = page;
        this.queryDelivery();
      },

      buttonColor(orderNumber) {
        var data = this.dataPicklist.filter(
          (x) => x.orderNumber == orderNumber && x.pickListType != "R"
        );
        let color = ''
        let text = ''
        let title = ''
  
        // Check if all elements have statusNo equal to 55
        let allStatusNo55 = data.every((element) => {
          return element.props.statusNo === 55 || element.pickListType === 'S';
        });
  
        if (allStatusNo55) {
          if (data.length > 0 && data[0].props.DPE !== undefined) {
            color = 'primary';
            text = 'View';
            title = 'Delivery Process - Completed'
          } else {
            color = 'success';
            text = 'Process';
            title = 'Delivery Process'
          }
        } else {
          color = 'secondary';
          text = 'View';
          title = 'Delivery Process'
        }
        let element = {
          variantColor : color,
          variantText : text,
          variantTitle : title
        }
        return element
      },
  
      detail(item, index, event) {
        if (item.orderNumber != undefined) {
          this.form.delivery = item;
          this.titleModal = this.buttonColor(item.orderNumber).variantTitle
          this.form.delivery.createdAt = dateFormat(item.createdAt)
        }
        
        var data = this.dataPicklist
          .filter((el) => {
            return el.orderNumber == this.form.delivery.orderNumber && el.pickListType != 'R';
          })
          .map((el) => {
            el.picklistNumber = el.picklistNo + "_" + el.picklistLine;
            this.form.orderNumber.push(el);
  
             // Tambahkan machineName berdasarkan syarat
              if (el.pickListType === 'C') {
                el.machineName = el.props.stationName || 'Not Set Up';
              }
  
            return el;
          })
          .filter((x) => 
            x.pickListType != "R"
          )
          // console.log(data);
  
        this.form.data = data;
  
        var hasStatusNot55 = this.form.data.some(
          (el) => el.props.statusNo != "55" && el.pickListType != "S" 
        );
        this.form.data.map((el) => {
          if (el.props.DPS != undefined || el.props.DPE != undefined) {
            hasStatusNot55 = true;
          }
        });
        this.disablePick = hasStatusNot55;
  
        var hasCode = false;
        this.form.data.map((el) => {
          if (el.props.DPS != undefined) {
            hasCode = true;
          }
          if (el.props.DPE != undefined) {
            hasCode = false;
          }
        });
  
        this.deliveryStarted = hasCode;
  
        return data
      },
      doFiltering(){
        this.orderNumber = this.searchBox
        this.queryDelivery()
      },
      simplyfiedItems(items) {
        return items.reduce((prev, next) => {
          if (next === null || next === undefined) {
            return prev;
          }
  
          let humanName = next.detail || next.name;
  
          return [...prev, humanName];
        }, []);
      },
    },
    computed: {
      delivery() {
        let delivery = this.$store.getters["delivery/getDelivery"].map(el => {
          var newOrderDate = dateFormat(el.orderDate).split(",")
          return {
            ...el,
            orderDate : newOrderDate[0] + newOrderDate[1].replace(/\./g, ':'),
          }
        })

        if (this.search && this.search.trim() !== '') {
          const searchTerm = this.search.trim().toLowerCase();
          return delivery.filter(item => item.picklistNo.toLowerCase().includes(searchTerm));
        } else {
          return delivery;
        }
      },
      permission() {
        return userAccess("completed delivery picking");
      },
      rows() {
        return this.delivery.length;
      },
    },
  };
  </script>
  
  <style>
  #myModal .modal-dialog {
    max-width: 90%; /* Atur lebar maksimum sesuai kebutuhan Anda */
    width: 100%;
    margin: auto;
  }

  @media (min-width: 761px) {
    .show-on-mobile {
      display: none !important;
    }
  }
  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  .spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #0077b3;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    margin-top: 20px;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }

  /* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
}
</style>
  